import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { Loader } from "@fremtind/jkl-loader-react";
import { formatInput } from "@fremtind/jkl-datepicker-react";
import { queries, components } from "@features/navneliste";
import { Trackingkey, track } from "src/tracking";
import { Side, SideHeader, SideInnhold } from "../components/Side";
import { Steg, SkjemaFlyt, SkjemaFlytProps, FormTrackingMeta } from "../components/SkjemaFlyt";

const { ChooseForsikring, ChooseInsured, MoreInfo, Receipt } = components.Innmelding;
const { queryKey, useMedlemList, useMedlemOppdateringStatus, useOppdaterMedlemmer } = queries.medlem;

type StegProps = components.Innmelding.StegProps;
type FormState = components.Innmelding.FormState;

const stegListe: Steg<StegProps>[] = [
    {
        stegnavn: "velg-medlem",
        element: (props) => <ChooseInsured {...props} />
    },
    {
        stegnavn: "velg-forsikring",
        element: (props) =>
            props.availableAvtaler ? (
                <ChooseForsikring {...props} />
            ) : (
                <Loader textDescription="Henter status på avtaler for medlem" />
            )
    },
    {
        stegnavn: "mer-info",
        element: (props) => <MoreInfo {...props} />
    },
    {
        stegnavn: "oppsummering",
        element: (props) => <Receipt {...props} />
    }
];

function MedlemInnmeldingSkjemaFlyt(
    props: Omit<StegProps, "onFrem" | "onTilbake" | "onAvbryt" | "onFerdig" | "onRestart"> & SkjemaFlytProps<StegProps>
) {
    return <SkjemaFlyt {...props} />;
}

export function MedlemInnmelding() {
    const formMethods = useForm<FormState>({
        defaultValues: {
            innmeldtDato: formatInput(new Date()) as unknown as Date,
            selectedAvtaleIDs: []
        }
    });
    const [meta, setMeta] = useState<FormTrackingMeta>();

    const queryClient = useQueryClient();
    const mutation = useOppdaterMedlemmer();
    const medlemmerQuery = useMedlemList();
    const oppdateringStatusQuery = useMedlemOppdateringStatus(formMethods.getValues().insured?.indeks);

    return (
        <Side>
            <SideHeader title="Meld inn medlem" />
            <SideInnhold
                loadingText="Henter list over medlemmer"
                query={medlemmerQuery}
                footerProps={{ feedbackProps: { formFlowName: meta?.flowName, formStepName: meta?.stepName } }}
            >
                <MedlemInnmeldingSkjemaFlyt
                    flytnavn="meld-inn-medlem"
                    formMethods={formMethods}
                    stegListe={stegListe}
                    setFormTrackingMeta={setMeta}
                    insuredList={medlemmerQuery.data?.entity.medlemmer || []}
                    availableAvtaler={
                        oppdateringStatusQuery.isFetching ? undefined : oppdateringStatusQuery.data?.avtaler
                    }
                    mutation={mutation}
                    onMutationSuccess={() => {
                        queryClient.invalidateQueries({ queryKey: queryKey.list() });

                        track({
                            hendelse: Trackingkey.Endre,
                            type: "meld inn medlem"
                        });
                    }}
                    oversiktLink="/medlemmer"
                />
            </SideInnhold>
        </Side>
    );
}
