import { Suspense } from "react";
import { Loader } from "@fremtind/jkl-loader-react";
import { ErrorBoundary } from "@sentry/react";
import { Await, useLoaderData } from "react-router-dom";
import type { ClaimOverview } from "src/model/gen";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { PrimaryLinkButton } from "@components/Button";
import { LenkeId, useExternalLinks } from "src/common/lenker";
import { ClaimsOverview } from "./ClaimsOverview";
import { NoClaimsMsg } from "./components/NoClaimsMsg";
import styles from "./ClaimsOverview.module.scss";

export const ClaimsOverviewComposer = () => {
    const { dataFetcher } = useLoaderData() as { dataFetcher: Array<ClaimOverview> };
    const meldSkadeLink = useExternalLinks().getLinkByLinkId(LenkeId.meldSkade)?.href;

    return (
        <Side>
            <SideHeader
                title="Skadesaker"
                actions={
                    <>
                        {meldSkadeLink && (
                            <PrimaryLinkButton to={meldSkadeLink} target="_blank">
                                Meld ny skadesak
                            </PrimaryLinkButton>
                        )}
                    </>
                }
            />
            <SideInnhold loadingText="Dine skadesaker" className={styles.claimsOverview}>
                <Suspense fallback={<Loader textDescription="Henter skadesaker" />}>
                    <Await
                        resolve={dataFetcher}
                        errorElement={
                            <>
                                <NoClaimsMsg buttonLink={meldSkadeLink} />
                                <ErrorBoundary />
                            </>
                        }
                    >
                        {(data: Array<ClaimOverview>) => <ClaimsOverview data={data} />}
                    </Await>
                </Suspense>
            </SideInnhold>
        </Side>
    );
};
