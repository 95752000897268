import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { queries, components } from "@features/navneliste";
import { Side, SideHeader, SideInnhold } from "../components/Side";
import { Typography } from "../components/Typography";
import { FormTrackingMeta, SkjemaFlyt, Steg } from "../components/SkjemaFlyt";
import { AvtaleProduktID } from "../features/agreements/avtale.model";
import "./AnsattEndreSykelonn.scss";

const { useAnsattList, useEndreAnsattSykelonn } = queries.ansatt;
const { VelgAnsatt, EndreLonn, Kvittering } = components.EndreSykelonn;

type EndreSykelonnStegProps = components.EndreSykelonn.EndreSykelonnStegProps;
type EndreSykelonnFormState = components.EndreSykelonn.EndreSykelonnFormState;

const stegListe: Steg<EndreSykelonnStegProps>[] = [
    {
        stegnavn: "velg-ansatt",
        element: (props) => <VelgAnsatt {...props} />
    },
    {
        stegnavn: "endre-lønn",
        element: (props) => <EndreLonn {...props} />
    },
    {
        stegnavn: "kvittering",
        element: (props) => <Kvittering {...props} />
    }
];

export const AnsattEndreSykelonn = () => {
    const ansatteQuery = useAnsattList();
    const formMethods = useForm<EndreSykelonnFormState>();
    const { indeks } = useParams<"indeks">();
    const mutation = useEndreAnsattSykelonn();

    const [meta, setMeta] = useState<FormTrackingMeta>();

    useEffect(() => {
        if (indeks) {
            formMethods.setValue("ansatteIndekser", [indeks]);
        }
    }, [formMethods, indeks]);

    const ansatteMedSykelonn =
        ansatteQuery.data?.entity.ansatte.filter((ansatt: { avtaler: any[] }) => {
            const avtale = ansatt.avtaler.find((avtale) => avtale.produktkode === AvtaleProduktID.SYKELONN);

            return !!avtale && avtale.innmeldingStatus === "INNMELDT";
        }) ?? [];

    return (
        <Side>
            <SideHeader title="Endre lønn på Sykelønn" />
            <SideInnhold
                loadingText="Henter liste over ansatte"
                query={ansatteQuery}
                footerProps={{ feedbackProps: { formFlowName: meta?.flowName, formStepName: meta?.stepName } }}
            >
                {ansatteMedSykelonn.length ? (
                    <>
                        <SkjemaFlyt
                            flytnavn="endre-sykelønn"
                            stegListe={stegListe.slice(indeks ? 1 : 0)}
                            setFormTrackingMeta={setMeta}
                            formMethods={formMethods}
                            {...{
                                ansatte: ansatteMedSykelonn,
                                mutation
                            }}
                        />
                    </>
                ) : (
                    <Typography>Det er ingen ansatte registrert på forsikringen Sykelønn...</Typography>
                )}
            </SideInnhold>
        </Side>
    );
};
