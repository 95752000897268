import { useContext } from "react";
import { SideHeader } from "@components/Side";
import ThemeContext from "src/context/ThemeContext";
import { Link } from "react-router-dom";
import { Link as JklLink } from "@fremtind/jkl-core";
import { LenkeId, useExternalLinks } from "src/common/lenker";
import { AgreementDetails } from "src/model/gen";
import { track, Trackingkey } from "src/tracking";
import { useMarkdown } from "src/common/hooks/useMarkdown";
import { useAgreementInfo } from "@features/agreements/hooks/useAgreementInfo";
import { List, ListItem } from "@fremtind/jkl-list-react";
import { SecondaryLinkButton } from "@components/Button";
import { AgreementButtons } from "../AgreementButtons/AgreementButtons";

interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementDetailsHeader = ({ agreementDetails }: Props) => {
    const hasLagringsforsikring = agreementDetails.stateDescription === "Lagringsforsikring";
    const product = useAgreementInfo(hasLagringsforsikring ? "Lagring" : agreementDetails.productCode);
    const { distributor } = useContext(ThemeContext);
    const sendClaimLink = useExternalLinks().getLinkByLinkId(LenkeId.meldSkade)?.href;

    return (
        <SideHeader
            title={agreementDetails.productName}
            lead={useMarkdown(product?.description)}
            actions={
                <>
                    {sendClaimLink && (
                        <SecondaryLinkButton
                            target="_blank"
                            to={sendClaimLink}
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.LenkeTrykk,
                                    lenkeId: LenkeId.meldSkade
                                });
                            }}
                        >
                            Meld skade
                        </SecondaryLinkButton>
                    )}
                    <AgreementButtons agreementDetails={agreementDetails} />
                </>
            }
            links={
                <nav>
                    <List>
                        <ListItem>
                            <Link
                                className="jkl-link"
                                to={`/faktura?avtalenr=${agreementDetails.agreementId}`}
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "snarveier-lenke-faktura"
                                    });
                                }}
                            >
                                Faktura
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                                className="jkl-link"
                                to={`/dokumenter`}
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "snarveier-lenke-dokumenter"
                                    });
                                }}
                            >
                                Alle dokumenter
                            </Link>
                        </ListItem>
                        {product?.productInfo && (
                            <ListItem>
                                <JklLink
                                    external
                                    target="_blank"
                                    onClick={() => {
                                        track({
                                            hendelse: Trackingkey.LenkeTrykk,
                                            lenkeId: product.productInfo!.id
                                        });
                                    }}
                                    href={
                                        distributor === "sb1"
                                            ? product.productInfo.href.sb1
                                            : product.productInfo.href.dnb
                                    }
                                >
                                    {product.productInfo.label}
                                </JklLink>
                            </ListItem>
                        )}
                        {product?.externalLinks &&
                            product.externalLinks.map((link) => (
                                <ListItem>
                                    <JklLink
                                        key={link.id}
                                        external
                                        target="_blank"
                                        href={distributor === "sb1" ? link.href.sb1 : link.href.dnb}
                                        onClick={() => {
                                            track({
                                                hendelse: Trackingkey.LenkeTrykk,
                                                lenkeId: link.id
                                            });
                                        }}
                                    >
                                        {link.label}
                                    </JklLink>
                                </ListItem>
                            ))}
                    </List>
                </nav>
            }
        />
    );
};
