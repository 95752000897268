import React from "react";
import { Await, defer, LoaderFunction, useLoaderData } from "react-router-dom";
import { Loader } from "@fremtind/jkl-loader-react";
import { AnimatePresence } from "framer-motion";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { brukerQueryKey, fetchBruker } from "@features/bruker/queries";
import { queryClient } from "src/common/queryClient";
import { Bruker } from "src/model/bruker.typer";
import { NumberBasedAgreementDto } from "src/model/gen";
import { useAntallsbaserteAvtaler, queryKey, requestAntallsbaserteAvtaler } from "../../antallsliste.queries";
import { EndreAntall } from "../../components";

export const loader: LoaderFunction = async () => {
    // const bruker = await queryClient.ensureQueryData<Bruker>(brukerQueryKey, fetchBruker);
    const bruker = await queryClient.ensureQueryData<Bruker>({ queryKey: brukerQueryKey, queryFn: fetchBruker });
    if (bruker) {
        return defer({
            avtaler: queryClient.fetchQuery({
                queryKey: [queryKey.list(bruker.gjeldendeOrganisasjonsnummer! as string)],
                queryFn: () => requestAntallsbaserteAvtaler(),
                staleTime: 10000
            })
        });
    }

    return Promise.resolve();
};

const Innhold = () => {
    const query = useAntallsbaserteAvtaler();
    return <EndreAntall avtaler={(query.data as NumberBasedAgreementDto[]) ?? []} />;
};

export const EndreAntallAnsatte = () => {
    const { avtaler } = useLoaderData() as { avtaler: NumberBasedAgreementDto[] };

    return (
        <Side compactTextOnly>
            <React.Suspense
                fallback={
                    <SideHeader
                        title="Endre antall"
                        lead="Hvis antall ansatte har endret seg mindre enn 10% trenger du ikke oppdatere avtalen før fornyelse."
                    />
                }
            >
                <Await resolve={avtaler} errorElement={<h1>Oh noes</h1>}>
                    {(data: NumberBasedAgreementDto[]) => {
                        return (
                            <SideHeader
                                title="Endre antall"
                                lead="Hvis antall ansatte har endret seg mindre enn 10% trenger du ikke oppdatere avtalen før fornyelse."
                            />
                        );
                    }}
                </Await>
            </React.Suspense>

            <SideInnhold loadingText="Henter avtaler">
                <AnimatePresence mode="wait">
                    <React.Suspense fallback={<Loader textDescription="Henter avtaler" />}>
                        <Await resolve={avtaler} errorElement={<h1>Oh noes</h1>}>
                            <Innhold />
                        </Await>
                    </React.Suspense>
                </AnimatePresence>
            </SideInnhold>
        </Side>
    );
};
